* {
  font-family: Roboto, sans-serif;
}
body {
  margin: 1rem;
  background-color: #fcfcf7;
  color: #000000;
}

.intro {
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
}

.entry {
  display: flex;
  justify-content: center;

  label {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }
  textarea {
    border-radius: 0.3rem;
    padding: 0.3rem;
    min-height: 20rem;
    width: 30vw;
  }
}
.buttons {
  padding: 0.4rem;
  text-align: center;
}

button {
  background-color: #a846a0;
  border: 1px solid #a846a0;
  padding: 0.7rem 1rem 0.5rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  text-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-weight: thin;
  box-shadow: 0rem 0.4rem 0.5rem rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
  transition: background-color 0.2s, box-shadow 0.1s;
  &:hover {
    background-color: #c97ec3;
    border: 1px solid #c97ec3;
    box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
  }
  &:active {
    background-color: #9e4297;
    border: 1px solid #9e4297;
  }
}

table {
  border: 0;
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.3rem;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
}

thead td {
  padding: 0.5rem;
}

thead {
  border-bottom: 2px solid #888888;
}

td {
  overflow: hidden;
  vertical-align: top;

  margin: 0;
  padding: 0.3rem 0.5rem;
  &:first-child {
    min-width: 10rem;
  }
  &:last-child {
    text-align: right;
  }
}

tr:nth-child(even) {
  background-color: #eeeeee;
}

tr {
  transition: box-shadow 0.2s;
}

tr:hover {
  box-shadow: 0 0 2rem rgba(255, 255, 255, 0.4) inset;
  transition: box-shadow 0s;
}

.certain {
  background-color: #a4df90;
  font-weight: bold;
}
tr:nth-child(even).certain {
  background-color: #97da81;
}

.possible {
  background-color: #ffca99;
}
tr:nth-child(even).possible {
  background-color: #ffc085;
}

.unlikely {
  color: #333333;
  font-style: italic;
}

.nothing {
  color: #888888;
  font-style: italic;
}

tr:nth-child(even).nothing {
  background-color: #f8f8f8;
}

.matchoptions {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.matchoptions:hover {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

span.joiner {
  color: #ffffff;
  font-style: italic;
  text-shadow: 0 0 1px #000;
}

.less-likely {
  opacity: 0.7;
  font-style: italic;
}

@media (prefers-contrast: more) {
  .less-likely {
    opacity: 1;
  }
  .unlikely {
    color: #111111;
  }
  .nothing {
    color: #333333;
  }
}

.options {
  text-align: center;
  label {
    margin: 0.5rem;
  }
  input {
    margin: 0.5rem;
  }
}
